import { useEffect, useState, useCallback } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import {
  Box,
  Container,
  Image,
  Heading,
  Text,
  Icon,
  Switch,
  useColorModeValue,
  useColorMode
} from '@chakra-ui/react';

import { MdDarkMode, MdLightMode } from 'react-icons/md';

import loginImage from '../assets/images/login-banner.jpg';
import LogoClaro from '../assets/images/logo-claro.svg';
import LogoOscuro from '../assets/images/logo-oscuro.svg';

import { useAuth } from '../hooks/useAuth';
import { fetchAxios } from '../axiosConfig';
import { doLogin, doLogout } from '../actions/authActions';

import LoadingScreen from '../components/LoadingScreen';

const MainLayout = () => {

  const navigate = useNavigate();

  const { dispatch } = useAuth();
  const { colorMode, toggleColorMode } = useColorMode();

  const bgBoxOutlet = useColorModeValue("white", "gray.700");
  const srcImage = colorMode === 'dark' ? LogoOscuro : LogoClaro;
  const iconForButton = colorMode === 'dark' ? <Icon as={MdLightMode} /> : <Icon as={MdDarkMode} />;

  const [isChecking, setIsChecking] = useState(true);

  const refreshUserToken = useCallback(async () => {

    try {

      const clientToken = window.localStorage.getItem('client_token');

      const axiosResponse = await fetchAxios('/auth/refresh_token', {
        method: 'POST',
        data: { oldToken: clientToken }
      });

      const { newToken, userData } = axiosResponse.data.dataInfo;

      dispatch(doLogin({
        isLoggedIn: true,
        user: userData,
        token: newToken
      }));

      window.localStorage.setItem('client_token', newToken);

      setIsChecking(false);
      navigate('/app/home');

    } catch (error) {

      setIsChecking(false);
      dispatch(doLogout());
      window.localStorage.removeItem('client_token');

    }

  }, [dispatch, navigate]);

  useEffect(() => {
    refreshUserToken();
  }, [refreshUserToken]);

  if (isChecking) return <LoadingScreen />;

  return (
    <Container
      maxW="container.xl"
      minH="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        borderRadius="6px"
        boxShadow="lg"
        display="flex"
        alignItems="center"
        overflow="hidden"
      >
        <Box
          padding="1.5em"
          width="28em"
          maxWidth="28em"
          height="40em"
          display="flex"
          flexDirection="column"
          alignItems="space-between"
          bg={bgBoxOutlet}
        >
          <Box display="flex" alignItems="flex-start">
            <Image
              src={srcImage}
              alt="Bartik Logo"
              width="80px"
            />
            <Box
              display="flex"
              alignItems="center"
              ml="auto"
            >
              {iconForButton}
              <Switch
                isChecked={colorMode === 'dark'}
                onChange={toggleColorMode}
                colorScheme="greenDark"
                size="md"
                ml="0.5em"
              />
            </Box>
          </Box>

          <Box height="100%">
            <Outlet />
          </Box>

          <Box width="100%">
            <Text align="center">Copyright 	&copy; 2021 Bartik Ing</Text>
          </Box>
        </Box>

        <Box
          flexShrink={0}
          display={{ base: 'none', sm: 'none', md: 'none', lg: 'block' }}
          position="relative"
        >
          <Box
            position="absolute"
            top="0"
            left="0"
            width="100%"
            height="100%"
            zIndex="1"
            bg="rgba(0, 0, 0, 0.5)"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <Heading color="white" fontSize="2xl">Bienvenido a</Heading>
            <Heading mt="0.5em" color="white">Visitik Panel Web</Heading>
          </Box>
          <Image
            width="30em"
            height="40em"
            objectFit="cover"
            objectPosition="-400px 0"
            src={loginImage}
            alt="Login Banner"
            sx={{ filter: "blur(1px)" }}
          />
        </Box>

      </Box>
    </Container>
  );
};

export default MainLayout;
