import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText
} from '@chakra-ui/react';

interface Props {
  hasCloseButton: boolean;
  bodyText?: string;
  isOpen: boolean;
  onClose: () => void;
  handleSendForm: (values: FormResetPasswordValues) => void;
  [x: string]: any;
};

interface FormResetPasswordValues {
  password: string;
  confirm: string;
};

const INITIAL_VALUES: FormResetPasswordValues = {
  password: '',
  confirm: ''
};

const FormResetSchema = Yup.object().shape({
  password: Yup
    .string()
    .min(8, 'La contraseña debe contener al menos 8 carácteres')
    .required('La contraseña es requerida'),
  confirm: Yup.string().oneOf([Yup.ref('password'), null], 'Las contraseñas deben coincidir')
});

const ChangePasswordModal = ({
  isOpen,
  onClose,
  hasCloseButton = false,
  bodyText,
  handleSendForm,
  ...rest
}: Props) => {
  
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      {...rest}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Nueva contraseña</ModalHeader>
        {hasCloseButton && <ModalCloseButton />}

        <ModalBody>
          {bodyText && <Text mb="1em">{bodyText}</Text>}

          <Formik
            initialValues={INITIAL_VALUES}
            onSubmit={handleSendForm}
            validationSchema={FormResetSchema}
          >
            {props => (
              <Form>

                <Field name="password">
                  {({ field, form }: any) => (
                    <FormControl isInvalid={form.errors.password && form.touched.password}>
                      <FormLabel htmlFor="password">Nueva contraseña</FormLabel>
                      <Input
                        {...field}
                        type="password"
                        id="password"
                        placeholder="Nueva Contraseña"
                        disabled={props.isSubmitting}
                      />
                      <FormHelperText>La contraseña debe contener al menos 8 carácteres</FormHelperText>
                      <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>

                <Field name="confirm">
                  {({ field, form }: any) => (
                    <FormControl mt="1em" isInvalid={form.errors.confirm && form.touched.confirm}>
                      <FormLabel htmlFor="confirm">Confirmación de contraseña</FormLabel>
                      <Input
                        {...field}
                        type="password"
                        id="confirm"
                        placeholder="Confirmación de contraseña"
                        disabled={props.isSubmitting}
                      />
                      <FormErrorMessage>{form.errors.confirm}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>

                <Box
                  my="1em"
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                >

                  {hasCloseButton &&
                    <Button
                      variant="ghost"
                      type="button"
                      onClick={onClose}
                      mr="0.6em"
                      disabled={props.isSubmitting}
                    >
                      Cerrar
                    </Button>
                  }

                  <Button
                    variant="solid"
                    colorScheme="greenDark"
                    type="submit"
                    isLoading={props.isSubmitting}
                  >
                    Guardar
                  </Button>
                </Box>

              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ChangePasswordModal;
