import { useState } from "react";

import {
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Select,
  FormErrorMessage,
  Input,
  Button,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Box,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { fetchAxios, formatHandledErrors } from "../../axiosConfig";
import { useAuth } from "../../hooks/useAuth";

interface Props {
  onClose: () => void;
}

export interface FormNewLicenseValues {
  nit: string;
  appName: string;
}

const ModalFormRequestLicense = ({ onClose }: Props) => {
  const { authState } = useAuth();
  const borderColor = useColorModeValue(
    "rgba(0, 0, 0, 0.1)",
    "rgba(255, 255, 255, 0.1)"
  );
  const [term, setTerm] = useState({
    nit: authState.user?.nit,
    appName: "none",
  });

  const toast = useToast();

  const handlerData = (e: any) => {
    setTerm({ ...term, [e.target.name]: e.target.value });
  };

  const handleChangeInput = (id: number, event: any) => {
    const newInputFields = inputFields.map((i: any) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });

    setInputFields(newInputFields);
  };

  const [inputFields, setInputFields] = useState([
    {
      id: 1,
      appName: "",
      OS: "none",
      keysCount: 1,
      num_activations: 1,
    },
  ]);

  const [error, setError] = useState({ errorNit: "", errorAppName: "" });

  const handleSudmit = async (e: any) => {
    e.preventDefault();

    try {
      if (term.appName.trim() === "") {
        setError({
          errorNit: "",
          errorAppName: "Debes seleccionar un tipo de aplicación válido",
        });
        return;
      }

      const results = {
        nit: term.nit,
        appName: term.appName,
        inputFields,
      };

      const uri = `/request/new-request/${authState.user?.nit}?typeRequest=Licencia`;
      const axiosResponse = await fetchAxios(uri, {
        method: "POST",
        headers: { authorization: `Bearer ${authState.token}` },
        data: results,
      });

      const { message } = axiosResponse.data.dataInfo;

      onClose();

      toast({
        title: "Solicitud le licensia exitosa",
        description: message,
        status: "success",
        isClosable: true,
        duration: 5000,
        position: "top-right",
      });
    } catch (error: any) {
      const formatedError = formatHandledErrors(error);
      toast({
        title: "Hubo un error",
        description: formatedError,
        status: "error",
        isClosable: true,
        duration: 5000,
        position: "top-right",
      });
    }
  };

  const prueba = () => {
    if (term.appName === "Visitik Admin") {
      setInputFields([
        {
          id: 1,
          appName: "Visitik Admin",
          OS: "none",
          keysCount: 1,
          num_activations: 1,
        },
        {
          id: 2,
          appName: "Visitik Operador",
          OS: "none",
          keysCount: 1,
          num_activations: 1,
        },
      ]);
    }

    if (term.appName === "Visitik Control") {
      setInputFields([
        {
          id: 1,
          appName: "Visitik Control",
          OS: "none",
          keysCount: 1,
          num_activations: 1,
        },
      ]);
    }

    if (term.appName === "Visitik Control Edificios") {
      setInputFields([
        {
          id: 1,
          appName: "Visitik Control Edificios",
          OS: "none",
          keysCount: 1,
          num_activations: 1,
        },
      ]);
    }
  };

  return (
    <Box>
      <form onSubmit={handleSudmit}>
        <ModalBody
          width="full"
          display="flex"
          alignItems="center"
          flexDirection={{ base: "column", md: "row" }}
          
        >
          <Box
            width={{ base: "100%", md: "50%" }}
            mr={{ base: "0", md: "1.5em" }}
            paddingRight={{ base: "0", md: "1.5em" }}
            borderRight={{ base: "0", md: `1px solid ${borderColor}` }}
          >
            <FormControl mb="0.8em">
              <FormLabel htmlFor="nit">Nit/Cedula</FormLabel>
              <Input
                id="nit"
                placeholder="Nit"
                autoComplete="off"
                spellCheck="false"
                name="nit"
                disabled={true}
                value={term.nit}
                onChange={handlerData}
              />
              <FormErrorMessage>{error}</FormErrorMessage>
            </FormControl>
          </Box>
          <Box
            width={{ base: "100%", md: "50%" }}
            mt={{ base: "1.5em", md: "0" }}
            pt={{ base: "1.5em", md: "0" }}
            borderTop={{ base: "1px solid gray", md: "none" }}
            
          >
            <FormControl mb="0.8em">
              <FormLabel htmlFor="appName">Aplicación</FormLabel>
              <Select
                id="appName"
                name="appName"
                onClick={prueba}
                onChange={handlerData}
                value={term.appName}
              >
                <option value="none" disabled>
                  Selecciona una aplicación
                </option>
                <option value="Visitik Admin">Visitik Admin</option>
                <option value="Visitik Control">Visitik Control</option>
                <option value="Visitik Control Edificios">
                  Visitik Control Edificios
                </option>
              </Select>
              <FormErrorMessage>{"hola"}</FormErrorMessage>
            </FormControl>
          </Box>
        </ModalBody>
  <Box   borderWidth="thin"
                    borderRadius="10px"
                    overflowX="scroll"
  >
        <Table variant="simple" size="md" >
          <Thead>
            <Tr>
              <Th>Aplicacion</Th>
              <Th>Sistema operativo</Th>
              <Th>Dispositivos a activar</Th>
              <Th>Cantidad de claves</Th>
            </Tr>
          </Thead>
          <Tbody>
            {term.appName !== "none" &&
              inputFields.map((data) => (
                <Tr key={data.id}>
                  <Td width="15%">{data.appName}</Td>
                  <Td>
                    <FormControl mb="0.8em">
                      <Select
                        id="OS"
                        name="OS"
                        onChange={(event) => handleChangeInput(data.id, event)}
                        value={data.OS}
                      >
                        <option value="none" disabled>
                          Selecciona un sistema operativo
                        </option>
                        <option value="WIN">Windows</option>
                        <option value="OSX">OSX (Mac OS)</option>
                        <option value="Linux">Linux</option>
                      </Select>
                      <FormErrorMessage>{"Hola"}</FormErrorMessage>
                    </FormControl>
                  </Td>
                  <Td>
                    <FormControl mb="0.8em">
                      <Input
                        id="num_activations"
                        placeholder="cantidad de dispositivos a activar"
                        autoComplete="off"
                        spellCheck="false"
                        type="number"
                        name="num_activations"
                        value={data.num_activations}
                        onChange={(event) => handleChangeInput(data.id, event)}
                      />
                      <FormErrorMessage>{"hola"}</FormErrorMessage>
                    </FormControl>
                  </Td>
                  <Td>
                    <FormControl mb="0.8em">
                      <Input
                        id="keysCount"
                        placeholder="cantidad de claves"
                        autoComplete="off"
                        spellCheck="false"
                        type="number"
                        name="keysCount"
                        value={data.keysCount}
                        onChange={(event) => handleChangeInput(data.id, event)}
                      />
                      <FormErrorMessage>{"hola"}</FormErrorMessage>
                    </FormControl>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
        </Box>
        <ModalFooter>
          <Button type="button" variant="ghost" onClick={onClose} mr="0.5em">
            Cerrar
          </Button>
          <Button colorScheme="darkBlue" type="submit">
            Guardar
          </Button>
        </ModalFooter>
      </form>
    </Box>
  );
};

export default ModalFormRequestLicense;
