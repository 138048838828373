import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { useState } from "react";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  handleSendForm: (values: FormResetPasswordValues) => void;
}

interface FormResetPasswordValues {
  oldPassword: string;
  password: string;
  confirm: string;
}

const INITIAL_VALUES: FormResetPasswordValues = {
  oldPassword: "",
  password: "",
  confirm: "",
};

const FormResetSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .min(8, "La contraseña debe contener al menos 8 carácteres")
    .required("La contraseña es requerida"),
  password: Yup.string()
    .min(8, "La contraseña debe contener al menos 8 carácteres")
    .required("La contraseña es requerida"),
  confirm: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Las contraseñas deben coincidir"
  ),
});

export default function UpdatePasswordUser({
  isOpen,
  onClose,
  handleSendForm,
}: Props) {
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showpassword, setShowpassword] = useState(false);
  const [showconfirm, setshowconfirm] = useState(false);

  const handleClickShowOldPassword = () => setShowOldPassword(!showOldPassword);
  const handleClickShowpassword = () => setShowpassword(!showpassword);
  const handleClickShowconfirm = () => setshowconfirm(!showconfirm);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Actualizar contraseña</ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <Formik
            initialValues={INITIAL_VALUES}
            onSubmit={handleSendForm}
            validationSchema={FormResetSchema}
          >
            {(props) => (
              <Form>
                <Field name="oldPassword">
                  {({ field, form }: any) => (
                    <FormControl
                      isInvalid={
                        form.errors.oldPassword && form.touched.oldPassword
                      }
                    >
                      <FormLabel htmlFor="oldPassword">
                        Contraseña actual
                      </FormLabel>

                      <InputGroup size="md">
                        <Input
                          pr="4.5rem"
                          type={showOldPassword ? "text" : "password"}
                          {...field}
                          id="oldPassword"
                          autoComplete="off"
                          placeholder="Nueva Contraseña"
                          disabled={props.isSubmitting}
                        />
                        <InputRightElement width="4.5rem">
                          <Button
                            h="1.75rem"
                            size="sm"
                            onClick={handleClickShowOldPassword}
                          >
                            {showOldPassword ? "Hide" : "Show"}
                          </Button>
                        </InputRightElement>
                      </InputGroup>

                      <FormHelperText>
                        La contraseña debe contener al menos 8 carácteres
                      </FormHelperText>
                      <FormErrorMessage>
                        {form.errors.oldPassword}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>

                <Field name="password">
                  {({ field, form }: any) => (
                    <FormControl
                      isInvalid={form.errors.password && form.touched.password}
                    >
                      <FormLabel htmlFor="password">Nueva contraseña</FormLabel>

                      <InputGroup size="md">
                        <Input
                          pr="4.5rem"
                          autoComplete="off"
                          type={showpassword ? "text" : "password"}
                          {...field}
                          id="password"
                          placeholder="Nueva Contraseña"
                          disabled={props.isSubmitting}
                        />
                        <InputRightElement width="4.5rem">
                          <Button
                            h="1.75rem"
                            size="sm"
                            onClick={handleClickShowpassword}
                          >
                            {showpassword ? "Hide" : "Show"}
                          </Button>
                        </InputRightElement>
                      </InputGroup>

                      <FormHelperText>
                        La contraseña debe contener al menos 8 carácteres
                      </FormHelperText>
                      <FormErrorMessage>
                        {form.errors.password}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>

                <Field name="confirm">
                  {({ field, form }: any) => (
                    <FormControl
                      mt="1em"
                      isInvalid={form.errors.confirm && form.touched.confirm}
                    >
                      <FormLabel htmlFor="confirm">
                        Confirmación de contraseña
                      </FormLabel>

                      <InputGroup size="md">
                        <Input
                          pr="4.5rem"
                          autoComplete="off"
                          type={showconfirm ? "text" : "password"}
                          {...field}
                          id="confirm"
                          placeholder="Confirmación de contraseña"
                          disabled={props.isSubmitting}
                        />
                        <InputRightElement width="4.5rem">
                          <Button
                            h="1.75rem"
                            size="sm"
                            onClick={handleClickShowconfirm}
                          >
                            {showconfirm ? "Hide" : "Show"}
                          </Button>
                        </InputRightElement>
                      </InputGroup>

                      <FormErrorMessage>{form.errors.confirm}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>

                <Box
                  my="1em"
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Button
                    variant="ghost"
                    type="button"
                    onClick={onClose}
                    mr="0.6em"
                    disabled={props.isSubmitting}
                  >
                    Cerrar
                  </Button>

                  <Button
                    variant="solid"
                    colorScheme="greenDark"
                    type="submit"
                    isLoading={props.isSubmitting}
                  >
                    Guardar
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
