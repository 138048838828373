import { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";

import * as Yup from "yup";

import {
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Select,
  FormErrorMessage,
  Input,
  Flex,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Alert,
  AlertIcon,
  Button,
} from "@chakra-ui/react";

import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
} from "@choc-ui/chakra-autocomplete";

import { fetchAxios, formatHandledErrors } from "../../axiosConfig";
import { useAuth } from "../../hooks/useAuth";

interface Props {
  handleSubmitForm: (values: FormNewLicenseValues) => void;
  onClose: () => void;
}

export interface FormNewLicenseValues {
  clientId: string;
  appName: string;
  OS: string;
  version: string;
  keysCount: number;
  num_activations: number;
}

const FORM_NEW_LICENSE_INITIAL_VALUES: FormNewLicenseValues = {
  clientId: "",
  appName: "none",
  OS: "none",
  version: "",
  keysCount: 1,
  num_activations: 1,
};

const FormNewLicenseSchema = Yup.object().shape({
  clientId: Yup.mixed().notOneOf(
    ["", null],
    "Debes selecciona un tipo de cliente válido"
  ),
  appName: Yup.string().notOneOf(
    ["none", null],
    "Debes seleccionar un tipo de aplicación válido"
  ),
  OS: Yup.string().notOneOf(
    ["none", null],
    "Debes seleccionar un sistema operativo válido"
  ),
  version: Yup.string().required(
    "La versión del software es un campo obligatorio"
  ),
  keysCount: Yup.number()
    .positive("El número no puede ser negativo")
    .min(1, "El número minimo a generar debe ser 1"),
  num_activations: Yup.number()
    .positive("El número no puede ser negativo")
    .min(1, "El número minimo a generar debe ser 1"),
});

const ModalFormNewLicense = ({ handleSubmitForm, onClose }: Props) => {
  const { authState } = useAuth();
  const [clients, setClients] = useState([]);
  const [getClientsError, setGetClientsError] = useState<string | null>(null);

  const getClients = async () => {
    try {
      const uri = `/client/get_clients/${authState.user?.id}`;
      const axiosResponse = await fetchAxios(uri, {
        headers: { authorization: `Bearer ${authState.token}` },
      });

      const { rows } = axiosResponse.data.dataInfo;

      setClients(rows);
    } catch (error: any) {
      const formatedError = formatHandledErrors(error);
      setGetClientsError(formatedError);
    }
  };

  useEffect(() => {
    getClients();
  }, []);

  return (
    <Formik
      initialValues={FORM_NEW_LICENSE_INITIAL_VALUES}
      onSubmit={handleSubmitForm}
      validationSchema={FormNewLicenseSchema}
    >
      {(props) => (
        <Form>
          <ModalBody>
            <Field name="clientId">
              {({ field, form }: any) => (
                <FormControl
                  mb="0.8em"
                  isInvalid={form.errors.clientId && form.touched.clientId}
                  isDisabled={props.isSubmitting}
                >
                  <FormLabel htmlFor="clientId">Cliente</FormLabel>
                  <AutoComplete
                    openOnFocus
                    rollNavigation
                    id="clientId"
                    value={field.value}
                    onChange={(value) => form.setFieldValue(field.name, value)}
                  >
                    <AutoCompleteInput placeholder="Selecciona un cliente" />
                    <AutoCompleteList>
                      {clients.map((item: any) => (
                        <AutoCompleteItem
                          key={String(item.id)}
                          value={String(item.id)}
                          label={`${item.nit} | ${item.businessName}`}
                        >
                          {item.nit} | {item.businessName}
                        </AutoCompleteItem>
                      ))}
                    </AutoCompleteList>
                  </AutoComplete>

                  {getClientsError && (
                    <Alert status="error" mt="0.5em">
                      <AlertIcon />
                      {getClientsError}
                    </Alert>
                  )}

                  <FormErrorMessage>{form.errors.clientId}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="appName">
              {({ field, form }: any) => (
                <FormControl
                  mb="0.8em"
                  isInvalid={form.errors.appName && form.touched.appName}
                  isDisabled={props.isSubmitting}
                >
                  <FormLabel htmlFor="appName">Aplicación</FormLabel>
                  <Select {...field} id="appName">
                    <option value="none" disabled>
                      Selecciona una aplicación
                    </option>
                    <option value="Visitik Admin">Visitik Admin</option>
                    <option value="Visitik Operarios">Visitik Operarios</option>
                    <option value="Visitik Control">Visitik Control</option>
                    <option value="Visitik Edificio">Visitik Edificio</option>
                  </Select>
                  <FormErrorMessage>{form.errors.appName}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="OS">
              {({ field, form }: any) => (
                <FormControl
                  mb="0.8em"
                  isInvalid={form.errors.OS && form.touched.OS}
                  isDisabled={props.isSubmitting}
                >
                  <FormLabel htmlFor="OS">Sistema Operativo</FormLabel>
                  <Select {...field} id="OS">
                    <option value="none" disabled>
                      Selecciona un sistema operativo
                    </option>
                    <option value="WIN">Windows</option>
                    <option value="OSX">OSX (Mac OS)</option>
                    <option value="Linux">Linux</option>
                  </Select>
                  <FormErrorMessage>{form.errors.OS}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="version">
              {({ field, form }: any) => (
                <FormControl
                  mb="0.8em"
                  isInvalid={form.errors.version && form.touched.version}
                  isDisabled={props.isSubmitting}
                >
                  <FormLabel htmlFor="version">Versión</FormLabel>
                  <Input
                    {...field}
                    id="version"
                    placeholder="Versión del software"
                    autoComplete="off"
                    spellCheck="false"
                  />
                  <FormErrorMessage>{form.errors.version}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="num_activations">
              {({ field, form }: any) => (
                <FormControl
                  mb="0.8em"
                  isInvalid={
                    form.errors.num_activations && form.touched.num_activations
                  }
                  isDisabled={props.isSubmitting}
                >
                  <FormLabel htmlFor="num_activations">
                    Numero de activacion
                  </FormLabel>
                  <Input
                    {...field}
                    id="num_activations"
                    placeholder="cantidad de dispositivos a activar"
                    autoComplete="off"
                    spellCheck="false"
                  />
                  <FormErrorMessage>
                    {form.errors.num_activations}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="keysCount">
              {({ field, form }: any) => (
                <FormControl
                  mb="0.8em"
                  isInvalid={form.errors.keysCount && form.touched.keysCount}
                  isDisabled={props.isSubmitting}
                >
                  <FormLabel htmlFor="keysCount">Cantidad de claves</FormLabel>
                  <Flex>
                    <NumberInput
                      maxW="100px"
                      mr="2rem"
                      min={1}
                      max={100}
                      onChange={(value) =>
                        form.setFieldValue(
                          field.name,
                          value.includes("-") ? 1 : value
                        )
                      }
                      value={field.value}
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                    <Slider
                      flex="1"
                      min={1}
                      max={100}
                      focusThumbOnChange={false}
                      value={field.value}
                      onChange={(value) =>
                        form.setFieldValue(field.name, value)
                      }
                    >
                      <SliderTrack>
                        <SliderFilledTrack />
                      </SliderTrack>
                      <SliderThumb fontSize="sm" boxSize="24px" />
                    </Slider>
                  </Flex>
                  <FormErrorMessage>{form.errors.keysCount}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </ModalBody>

          <ModalFooter>
            <Button
              type="button"
              variant="ghost"
              disabled={props.isSubmitting}
              onClick={onClose}
              mr="0.5em"
            >
              Cerrar
            </Button>
            <Button
              colorScheme="darkBlue"
              type="submit"
              disabled={props.isSubmitting || !!getClientsError}
            >
              Guardar
            </Button>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  );
};

export default ModalFormNewLicense;
