import { useParams, useNavigate } from 'react-router-dom';

import {
  Box,
  Heading,
  Text,
  Button
} from '@chakra-ui/react';

const RecoveryMessage = () => {

  const params = useParams();
  const navigate = useNavigate();

  return (
    <Box mt="2em">
      <Heading mb="0.5em">Recuperación exitosa</Heading>

      <Text>
        La recuperación de la contraseña fue exitosa, revisa el correo electrónico asociado
        al número de identificación <Text as="span">{params.numberId}</Text>.
      </Text>
      <Text mt="1em">
        Regresa al inicio de sesión e ingresa con la contraseña generada
      </Text>

      <Button
        mt="1em"
        isFullWidth
        colorScheme="greenDark"
        onClick={() => navigate('/login')}
      >
        Regresar al inicio de sesión
      </Button>

    </Box>
  );
};

export default RecoveryMessage;
