import axios, { AxiosError } from "axios";
export const fetchAxios = axios.create({ baseURL: process.env.REACT_APP_API_URL });
export const fetchAxiosImg = axios.create({ baseURL: process.env.REACT_APP_IMAGES_API_URL });
export const fetchMultimedia = axios.create({ baseURL: process.env.REACT_APP_IMAGES_API_URL });

export const setRequestToken = (token: string): void => {

  const formatedToken = `Bearer ${token}`;
  fetchAxios.defaults.headers.common['authorization'] = formatedToken;

};

export const removeRequestToken = (): void => {
  fetchAxios.defaults.headers.common['Authorization'] = '';
};

export const formatHandledErrors = (error: AxiosError): string => {

  let errMsg = '[Error] Ocurrio un error inesperado';

  if (error.response)
    errMsg = `[Error: ${error.response.status}]: ${error.response.data.errors}`;
  else if (error.request)
    errMsg = `[Error: Indefinido]: Ocurrio un error realizando la petición, asegurate de tener conexión a internet`;
  else
    errMsg = `[Error: Cliente] ${error.message}`;

  return errMsg;

};