import * as React from "react";

import { Formik, Form, Field } from "formik";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import {
  Box,
  Heading,
  Input,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Button,
  Checkbox,
  useColorMode,
  Link,
  Alert,
  AlertIcon,
  AlertDescription,
  CloseButton,
  Text,
} from "@chakra-ui/react";

import * as Yup from "yup";

import { fetchAxios, formatHandledErrors } from "../axiosConfig";
import { useAuth } from "../hooks/useAuth";
import { doLogin } from "../actions/authActions";
import useIsMounted from "../hooks/useIsMounted";

const LoginSchema = Yup.object().shape({
  numberId: Yup.string()
    .min(4, "Demasiado corto!")
    .required("El número de identificación es un número requerido"),
  password: Yup.string().required("La contraseña es un campo requerido"),
});

const { useState, useRef, useLayoutEffect } = React;

interface FormValuesProps {
  numberId: string;
  password: string;
}

const initialValues: FormValuesProps = {
  numberId: "",
  password: "",
};

const Login = () => {
  const formRef = useRef<any>(null!);

  const navigate = useNavigate();
  const isMounted = useIsMounted();

  const { colorMode } = useColorMode();
  const { dispatch } = useAuth();

  const [rememberUser, setRememberUser] = useState(false);
  const [errorMessage, setErrorMessage] = useState<null | string>(null);

  const handleClearError = () => setErrorMessage(null);

  const handleChangeRememberUser = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.checked;

    if (value)
      window.localStorage.setItem(
        "user_remembered",
        formRef.current.values.numberId
      );
    else window.localStorage.removeItem("user_remembered");

    setRememberUser(value);
  };

  const handleSendForm = async (values: FormValuesProps) => {
    try {
      const { numberId, password } = values;

      const axiosResponse = await fetchAxios("/auth/login", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        data: { numberId, password },
      });

      const { user, token, isTemporalPass } = axiosResponse.data.dataInfo;

      dispatch(doLogin({ user, token, isTemporalPass, isLoggedIn: true }));

      window.localStorage.setItem("client_token", token);

      navigate("/app/home");
    } catch (error: any) {
      setErrorMessage(formatHandledErrors(error));

      if (isMounted()) {
        setTimeout(() => {
          handleClearError();
        }, 2000);
      }
    }
  };

  useLayoutEffect(() => {
    const userRemembered = window.localStorage.getItem("user_remembered");

    if (userRemembered) {
      formRef.current.values.numberId = userRemembered;
      setRememberUser(true);
    }
  }, []);

  return (
    <Box mt="2em">
      <Heading mb="0.5em">Inicio de Sesión</Heading>

      <Formik
        initialValues={initialValues}
        validationSchema={LoginSchema}
        innerRef={formRef}
        onSubmit={handleSendForm}
      >
        {(props) => (
          <Form>
            <Field name="numberId">
              {({ field, form }: any) => (
                <FormControl
                  mb="0.5em"
                  isInvalid={form.errors.numberId && form.touched.numberId}
                  isDisabled={props.isSubmitting}
                  isRequired
                >
                  <FormLabel htmlFor="numberId">
                    Número de identificación
                  </FormLabel>
                  <Input
                    {...field}
                    id="numberId"
                    placeholder="Número de identificación"
                  />
                  <FormErrorMessage>{form.errors.numberId}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="password">
              {({ field, form }: any) => (
                <FormControl
                  isInvalid={form.errors.password && form.touched.password}
                  isDisabled={props.isSubmitting}
                  isRequired
                >
                  <FormLabel htmlFor="password">Contraseña</FormLabel>
                  <Input
                    type="password"
                    id="password"
                    placeholder="*********"
                    {...field}
                  />
                  <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Box
              my="1.2em"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              flexDirection={{ base: "column", sm: "column", md: "row" }}
            >
              <Checkbox
                isChecked={rememberUser}
                onChange={handleChangeRememberUser}
              >
                Recordar usuario
              </Checkbox>{" "}
              <RouterLink to="/recovery">
                <Link
                  color={colorMode === "dark" ? "white" : "darkBlue"}
                  as="span"
                  mt={{ base: "0.5em", sm: "0.5em", md: "0" }}
                >
                  ¿Olvidaste tu contraseña?
                </Link>
              </RouterLink>
            </Box>

            <Box paddingBottom="3px" textAlign={"center"} fontSize={"14px"}>
              <Text>
                Al continuar, aceptas los{" "}
                <Link color="blue.500"> términos y condiciones</Link>{" "}
                establecidos.
              </Text>
            </Box>

            <Button
              isFullWidth
              mt={2}
              colorScheme="greenDark"
              isLoading={props.isSubmitting}
              loadingText="Enviando"
              type="submit"
            >
              Ingresar
            </Button>

            {errorMessage && (
              <Alert status="error" mb="0.6em">
                <AlertIcon />
                <AlertDescription>{errorMessage}</AlertDescription>
                <CloseButton
                  onClick={handleClearError}
                  position="absolute"
                  right="8px"
                  top="8px"
                />
              </Alert>
            )}
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default Login;
