import {
  Box,
  Container,
  Text,
  Heading,
  useColorModeValue,
  Spinner,
  Button,
} from "@chakra-ui/react";
import { FiDownload } from "react-icons/fi";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchAxios, formatHandledErrors } from "../axiosConfig";
import { useAuth } from "../hooks/useAuth";
import dayjs from "dayjs";
import es from "dayjs/locale/es";

export default function ReportDetails() {
  const { authState } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [report, setReport] = useState<any>({});
  const [file, setFile] = useState<any>({});
  const [error, seterror] = useState<any>(null);

  const { id } = useParams();

  const handleGetDetail = async () => {
    setIsLoading(true);

    try {
      const uri = `/repots/get-report-detail/${id}`;
      const axiosResponse = await fetchAxios(uri, {
        method: "GET",
        headers: { authorization: `Bearer ${authState.token}` },
      });

      const { reports, files } = axiosResponse.data.dataInfo;

      setReport(reports);
      setFile(files);

      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      const formatedError = formatHandledErrors(error);
      seterror(formatedError);
    }
  };

  const document = file
    ? `${process.env.REACT_APP_IMAGES_API_URL}/document/document/${file.nameServices}`
    : null;

  useEffect(() => {
    handleGetDetail();
  }, []);

  return (
    <Container
      maxW={"7xl"}
      width="full"
      bg={useColorModeValue("white", "gray.700")}
      p="5"
      wordBreak="break-all"
      borderRadius="10px"
    >
      <Box>
        {isLoading && (
          <>
            <Spinner mr="0.5em" /> Cargando
          </>
        )}

        {error && <Text>{error}</Text>}

        <Heading size="lg">{report.title} </Heading>
        <br />
        <Text>ASUNTO: {report.text_msg} </Text>
        <br />
        <Text isTruncated>
          {dayjs(report.creationdate).isValid()
            ? dayjs(report.creationdate)
                .locale(es)
                .format("DD [de] MMMM [de] YYYY")
            : "No Registrado"}
        </Text>
        <br />
        <br />
        <a href={`${document}`} download="PDF">
          <Button
            colorScheme="greenDark"
            leftIcon={<FiDownload />}
            href={`${document}`}
          >
            Descargar PDF
          </Button>
        </a>
      </Box>
    </Container>
  );
}
