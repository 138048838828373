import { useState, useEffect } from "react";
import {
  Box,
  Text,
  Button,
  Heading,
  useColorModeValue,
  Icon,
  Divider,
  Spinner,
  Alert,
  AlertIcon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { FiPlus, FiInfo } from "react-icons/fi";
import FiltersForm, {
  FiltersFormValues,
} from "../components/users/FiltersForm";
import UserCard from "../components/users/UserCard";
import Pagination from "../components/Pagination";
import { fetchAxios, formatHandledErrors } from "../axiosConfig";
import { useAuth } from "../hooks/useAuth";
import ModalFormNewClient, {
  FormNewClientValues,
} from "../components/license/ModalFormNewClient";
import ModalFormAssignLicense from "../components/license/ModalFormAssignLicense";

interface UserProps {
  id: number;
  nit: string;
  businessName: string;
  email?: string;
  imageUrl?: string;
  status: number;
  clientType: string;
  clientsParent: string;
  clientsParentNit: string;
  licenses: [];
}

const REGISTERS_LIMIT = 10;

const INITIAL_FILTER_VALUES = {
  clientIdFilter: "none",
  appName: "none",
  status: "none",
  nameOrDni: "",
};

export interface FormNewLicenseValues {
  idsubClient: string;
  idlicense: number;
}

const Users = () => {
  const { authState } = useAuth();
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState(INITIAL_FILTER_VALUES);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [total, setTotal] = useState(0);
  const [usersList, setUsersList] = useState<any>([]);
  const [modalOptions, setModalOptions] = useState({
    title: "",
    formName: "new_client",
    size: "md",
  });
  const [modalNewClients, setmodalNewClients] = useState(false);
  const [assignLicense, setassignLicense] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [getUsersErrorMsg, setGetUsersErrorMsg] = useState<string | null>(null);
  const toast = useToast();
  const { onClose } = useDisclosure();

  const handleOpenModal = (formName: string, size: string = "md") => {
    let title = "";

    switch (formName) {
      case "new_client":
        title = "Registrar nuevo cliente";
        break;
      case "new_license":
        title = "Generar nueva licencia";
        break;
      case "get_license":
        title = "Solicitar licencia";
        break;
      default:
        break;
    }

    setModalOptions((prevState) => ({
      ...prevState,
      title,
      formName,
      size,
    }));

    setmodalNewClients(true);
  };

  const handleSendFiltersForm = (values: FiltersFormValues) => {
    setFilters({
      clientIdFilter: values.license,
      appName: values.app,
      status: values.status,
      nameOrDni: values.search,
    });
  };

  const handleResetForm = () => setFilters(INITIAL_FILTER_VALUES);

  const handleGetUsersList = async () => {
    setIsLoading(true);

    try {
      const role = authState.user?.clientType === "Bartik" ? "admin" : "user";
      const uri = `/user/get_users/${authState.user?.id}?page=${page}&limit=${REGISTERS_LIMIT}&role=${role}`;

      const axiosResponse = await fetchAxios(uri, {
        method: "POST",
        headers: { authorization: `Bearer ${authState.token}` },
        data: filters,
      });

      const { count, rows } = axiosResponse.data.dataInfo;

      setUsersList(rows);
      setTotal(count);

      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      const formatedError = formatHandledErrors(error);
      setGetUsersErrorMsg(formatedError);
    }
  };

  const handleAddNewUser = (newUser: any) => {
    setUsersList((prevState: any) => [...prevState, newUser]);
  };

  const handleUpdateUserStatus = (numberId: string, newStatus: number) => {
    setUsersList((prevState: any) =>
      prevState.map((item: any) => {
        if (item.nit === numberId) {
          return {
            ...item,
            status: newStatus,
          };
        }

        return item;
      })
    );
  };

  const handleChangePage = (numberPage: number) => setPage(numberPage);

  const handleSendCreateClient = async (values: FormNewClientValues) => {
    setIsSubmitting(true);
    try {
      const uri = `/client/register_new_subClient/${authState.user?.id}`;
      const axiosResponse = await fetchAxios(uri, {
        method: "PUT",
        headers: { authorization: `Bearer ${authState.token}` },
        data: values,
      });

      const { message, userId, type } = axiosResponse.data.dataInfo;

      const newUser = {
        id: userId,
        nit: values.nit,
        businessName: values.businessName,
        email: values.email,
        image: null,
        status: 1,
        licenses: [],
      };

      handleAddNewUser(newUser);

      toast({
        title: type === "warning" ? "Atención" : "Registro creado",
        description: message,
        status: type,
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });

      setIsSubmitting(false);
      setmodalNewClients(false);
    } catch (error: any) {
      const formatedError = formatHandledErrors(error);
      toast({
        title: "Error",
        description: error.response.data?.dataInfo?.message
          ? error.response.data?.dataInfo?.message
          : formatedError,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });

      setIsSubmitting(false);
    }
  };

  const closeModal = () => {
    setmodalNewClients(false);
  };

  const closeModalAssignLicense = () => {
    setassignLicense(false);
  };

  const handleSubmit = async (values: FormNewLicenseValues) => {
    setIsSubmitting(true);
    try {
      const uri = `/client/assign-license/${authState.user?.id}`;
      const axiosResponse = await fetchAxios(uri, {
        method: "POST",
        headers: { authorization: `Bearer ${authState.token}` },
        data: values,
      });

      const { message, type } = axiosResponse.data.dataInfo;

      toast({
        title:
          type === "warning"
            ? "Ocurrio un problema"
            : "Licencia asignada con exito",
        description: message,
        status: type,
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });

      setIsSubmitting(false);
      setassignLicense(false);
      handleGetUsersList();
    } catch (error: any) {
      const formatedError = formatHandledErrors(error);

      toast({
        title: "Error",
        description: formatedError,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });

      setIsSubmitting(false);
    }
  };

  const updateEmailClient = (id: number, newEmail: string) => {
    const newState = usersList.map((item: any) => {
      if (item.id === id) {
        return {
          ...item,
          email: newEmail,
        };
      }

      return item;
    });

    setUsersList(newState);
  };

  const handleUpdateUserImage = (numberId: string, image: any) => {
    setUsersList((prevState: any) =>
      prevState.map((item: any) => {
        if (item.nit === numberId) {
          return {
            ...item,
            imageUrl: image,
          };
        }

        return item;
      })
    );
  };

  useEffect(() => {
    handleGetUsersList();
  }, [filters, page]);

  console.log(usersList);

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        bg={useColorModeValue("white", "gray.700")}
        padding="1em"
        borderRadius="10px"
        sx={{
          "@media (max-width: 900px)": {
            flexDir: "column",
          },
        }}
      >
        <Heading>
          {authState.user?.clientType === "Bartik"
            ? "Sub-clientes y Licencias"
            : "Clientes y Licencias"}
        </Heading>

        {authState.user?.clientType !== "Bartik" && (
          <>
            <Button
              ml="auto"
              mr="1em"
              colorScheme="greenDark"
              leftIcon={<FiPlus />}
              onClick={() => handleOpenModal("new_client", "5xl")}
              sx={{
                "@media (max-width: 900px)": {
                  marginTop: 5,
                  marginLeft: 4,
                  marginBottom: 5,
                },
              }}
            >
              Nuevo Cliente
            </Button>

            <Button
              colorScheme="greenDark"
              leftIcon={<FiPlus />}
              onClick={() => setassignLicense(true)}
            >
              Asignar licencia
            </Button>
          </>
        )}
      </Box>

      <Box
        bg={useColorModeValue("white", "gray.700")}
        padding="1em"
        borderRadius="10px"
        mt="1em"
      >
        <Text verticalAlign="center">
          <Icon as={FiInfo} color="blue.500" mr="0.5em" display="inline" />
          Gestiona los usuarios de las aplicaciones de
          <Text as="span" mx="0.2em" fontWeight="bold">
            Visitik Admin
          </Text>{" "}
          y
          <Text as="span" mx="0.2em" fontWeight="bold">
            Visitik Operarios
          </Text>
          asociados a tus licencias
        </Text>

        <Text verticalAlign="center">
          <Icon as={FiInfo} color="blue.500" mr="0.5em" display="inline" />
          Una vez creado el usuario se enviara un Email al correo registrado con
          la contraseña temporal, por favor asegurate de escribir un correo
          válido.
        </Text>

        <Divider my="1em" />

        <Box width="full" display="flex" flexDirection="column">
          <FiltersForm
            onSubmit={handleSendFiltersForm}
            resetForm={handleResetForm}
          />

          <Divider my="1em" />

          <Box width="full" position="relative" mb="1em">
            <Box
              height="full"
              position="absolute"
              top="0"
              left="10px"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              {isLoading && (
                <>
                  <Spinner mr="0.5em" /> Cargando
                </>
              )}
            </Box>
            <Pagination
              total={total}
              page={page}
              itemsPerPage={REGISTERS_LIMIT}
              handleChangePage={handleChangePage}
              width="full"
              justifyContent="flex-end"
            />
          </Box>

          {getUsersErrorMsg && (
            <Alert status="error">
              <AlertIcon />
              {getUsersErrorMsg}
            </Alert>
          )}

          {usersList.map((item: UserProps) => (
            <UserCard
              key={item.id}
              id={item.id}
              nit={item.nit}
              businessName={item.businessName}
              image={item.imageUrl}
              email={item.email}
              status={item.status}
              clientType={item.clientType}
              clientsParent={item.clientsParent}
              clientsParentNit={item.clientsParentNit}
              licenses={item.licenses}
              updateStatus={handleUpdateUserStatus}
              updateEmailClient={updateEmailClient}
              handleUpdateUserImage={handleUpdateUserImage}
            />
          ))}
        </Box>
      </Box>

      <Modal
        isOpen={modalNewClients}
        onClose={closeModal}
        closeOnEsc={!isSubmitting}
        closeOnOverlayClick={!isSubmitting}
        size={modalOptions.size}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{modalOptions.title}</ModalHeader>
          <ModalCloseButton />

          {modalOptions.formName === "new_client" && (
            <ModalFormNewClient
              handleSubmitForm={handleSendCreateClient}
              onClose={closeModal}
            />
          )}
        </ModalContent>
      </Modal>

      <Modal isOpen={assignLicense} onClose={closeModalAssignLicense}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Asignar licencias</ModalHeader>
          <ModalCloseButton />

          <ModalFormAssignLicense
            handleSubmitFor={handleSubmit}
            onClose={onClose}
          />
        </ModalContent>
      </Modal>
    </>
  );
};

export default Users;
