import { Formik, Form, Field } from 'formik';

import * as Yup from 'yup';

import {
  ModalBody,
  ModalFooter,
  Input,
  FormControl,
  FormLabel,
  Select,
  FormErrorMessage,
  Button,
  Box,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { useAuth } from '../../hooks/useAuth';

interface Props {
  handleSubmitForm: (values: FormNewClientValues) => void;
  onClose: () => void;
};

export interface FormNewClientValues {
  nit: string;
  businessName: string;
  email: string;
  clientType: string;
  licenseId: number;
  adress: string;
  country: string;
  city: string;
  contactName: string;
  contactPhone: string;
};

const FORM_NEW_CLIENT_INITIAL_VALUES: FormNewClientValues = {
  nit: '',
  businessName: '',
  email: '',
  clientType: 'none',
  licenseId: 0,
  adress: '',
  country: '',
  city: '',
  contactName: '',
  contactPhone: ''
};

const FormNewClientSchema = Yup.object().shape({
  clientType: Yup.mixed().notOneOf(['none', null], 'Debes selecciona un tipo de cliente válido'),
  nit: Yup.string().required('El NIT | Número de identificación es un campo obligatorio'),
  businessName: Yup.string().required('La Razón social | Nombre es un campo obligatorio'),
  email: Yup.string().email('Ingresa un email válido').required('El email es un campo obligatorio'),
  adress: Yup.string().required('La dirección es un campo obligatorio'),
  country: Yup.string().required('El país es un campo obligatorio'),
  city: Yup.string().required('La ciudad es un campo obligatorio'),
  contactName: Yup.string().required('El nombre de contacto es un campo obligatorio'),
  contactPhone: Yup.string().required('El número de contacto es un campo obligatorio'),
});


const ModalFormNewClient = ({ handleSubmitForm, onClose }: Props) => {
  const { authState } = useAuth();
  const borderColor = useColorModeValue('rgba(0, 0, 0, 0.1)', 'rgba(255, 255, 255, 0.1)');
  const textColor = useColorModeValue('gray.400', 'gray.300');

  return (
    <Formik
      initialValues={FORM_NEW_CLIENT_INITIAL_VALUES}
      validationSchema={FormNewClientSchema}
      onSubmit={handleSubmitForm}
    >
      {props => (
        <Form>
          <Box width="100%" px="1.5em" mb="1em">
            <Text fontSize="0.9em" color={textColor}>* Todos los campos del formulario son obligatorios</Text>
          </Box>

          <ModalBody
            width="full"
            display="flex"
            alignItems="center"
            flexDirection={{ base: 'column', md: 'row' }}
          >
            <Box
              width={{ base: '100%', md: '50%' }}
              mr={{ base: '0', md: '1.5em' }}
              paddingRight={{ base: '0', md: '1.5em' }}
              borderRight={{ base: '0', md: `1px solid ${borderColor}` }}
            >

              {authState.user?.clientType === 'Bartik' ? <Field name="clientType">
                {({ field, form }: any) => (
                  <FormControl
                    mb="0.8em"
                    isInvalid={form.errors.clientType && form.touched.clientType}
                    isDisabled={props.isSubmitting}
                  >
                    <FormLabel htmlFor="clientType">Tipo de cliente</FormLabel>
                    <Select {...field} id="clientType" w="auto">
                      <option disabled value="none">Elige un tipo de cliente</option>
                      <option value="Empresarial">Empresarial</option>
                      <option value="Individual">Individual</option>
                    </Select>
                    <FormErrorMessage>{form.errors.clientType}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
                :
                <Field name="clientType">
                  {({ field, form }: any) => (
                    <FormControl
                      mb="0.8em"
                      isInvalid={form.errors.clientType && form.touched.clientType}
                      isDisabled={props.isSubmitting}
                    >
                      <FormLabel htmlFor="clientType">Tipo de cliente</FormLabel>
                      <Select {...field} id="clientType">
                        <option disabled value="none">Elige un tipo de cliente</option>
                        <option value="Individual">Individual</option>
                      </Select>
                      <FormErrorMessage>{form.errors.clientType}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>


              }


              <Field name="nit">
                {({ field, form }: any) => (
                  <FormControl
                    mb="0.8em"
                    isInvalid={form.errors.nit && form.touched.nit}
                    isDisabled={props.isSubmitting}
                  >
                    <FormLabel htmlFor="nit">NIT / Número de identificación</FormLabel>
                    <Input
                      {...field}
                      id="nit"
                      placeholder="123456789-01 / 123456789"
                      spellCheck="false"
                      autoComplete="off"
                    />
                    <FormErrorMessage>{form.errors.nit}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="businessName">
                {({ field, form }: any) => (
                  <FormControl
                    mb="0.8em"
                    isInvalid={form.errors.businessName && form.touched.businessName}
                    isDisabled={props.isSubmitting}
                  >
                    <FormLabel htmlFor="businessName">Razón social / Nombre</FormLabel>
                    <Input
                      {...field}
                      id="businessName"
                      placeholder="Razón social"
                      spellCheck="false"
                      autoComplete="off"
                    />
                    <FormErrorMessage>{form.errors.businessName}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="email">
                {({ field, form }: any) => (
                  <FormControl
                    mb="0.8em"
                    isInvalid={form.errors.email && form.touched.email}
                    isDisabled={props.isSubmitting}
                  >
                    <FormLabel htmlFor="email">Email</FormLabel>
                    <Input
                      {...field}
                      id="email"
                      type="email"
                      placeholder="client@email.com"
                      spellCheck="false"
                      autoComplete="off"
                    />
                    <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Box>

            <Box
              width={{ base: '100%', md: '50%' }}
              mt={{ base: '1.5em', md: '0' }}
              pt={{ base: '1.5em', md: '0' }}
              borderTop={{ base: '1px solid gray', md: 'none' }}
            >

              <Field name="adress">
                {({ field, form }: any) => (
                  <FormControl
                    mb="0.8em"
                    isInvalid={form.errors.adress && form.touched.adress}
                    isDisabled={props.isSubmitting}
                  >
                    <FormLabel htmlFor="adress">Dirección</FormLabel>
                    <Input
                      {...field}
                      id="adress"
                      placeholder="Dirección"
                      spellCheck="false"
                      autoComplete="off"
                    />
                    <FormErrorMessage>{form.errors.adress}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Box
                display="flex"
                alignItems="center"
                width="full"
                flexDirection={{ base: 'column', md: 'row' }}
              >

                <Field name="country">
                  {({ field, form }: any) => (
                    <FormControl
                      mb="0.8em"
                      mr={{ base: '0', md: '0.5em' }}
                      isInvalid={form.errors.country && form.touched.country}
                      isDisabled={props.isSubmitting}
                    >
                      <FormLabel htmlFor="country">País</FormLabel>
                      <Input
                        {...field}
                        id="country"
                        placeholder="Colombia"
                        spellCheck="false"
                        autoComplete="off"
                      />
                      <FormErrorMessage>{form.errors.country}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>

                <Field name="city">
                  {({ field, form }: any) => (
                    <FormControl
                      mb="0.8em"
                      isInvalid={form.errors.city && form.touched.city}
                      isDisabled={props.isSubmitting}
                    >
                      <FormLabel htmlFor="city">Ciudad</FormLabel>
                      <Input
                        {...field}
                        id="city"
                        placeholder="Bogotá"
                        spellCheck="false"
                        autoComplete="off"
                      />
                      <FormErrorMessage>{form.errors.city}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>

              </Box>

              <Field name="contactName">
                {({ field, form }: any) => (
                  <FormControl
                    mb="0.8em"
                    isInvalid={form.errors.contactName && form.touched.contactName}
                    isDisabled={props.isSubmitting}
                  >
                    <FormLabel htmlFor="contactName">Nombre de contacto</FormLabel>
                    <Input
                      {...field}
                      id="contactName"
                      placeholder="Nombre de contacto"
                      spellCheck="false"
                      autoComplete="off"
                    />
                    <FormErrorMessage>{form.errors.contactName}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="contactPhone">
                {({ field, form }: any) => (
                  <FormControl
                    mb="0.8em"
                    isInvalid={form.errors.contactPhone && form.touched.contactPhone}
                    isDisabled={props.isSubmitting}
                    isRequired
                  >
                    <FormLabel htmlFor="contactPhone">Número de contacto</FormLabel>
                    <Input
                      {...field}
                      id="contactPhone"
                      placeholder="123456789"
                      spellCheck="false"
                      autoComplete="off"
                    />
                    <FormErrorMessage>{form.errors.contactPhone}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

            </Box>
          </ModalBody>

          <ModalFooter>
            <Button
              variant="ghost"
              mr={3}
              onClick={onClose}
              disabled={props.isSubmitting}
            >
              Cerrar
            </Button>
            <Button
              colorScheme="darkBlue"
              type="submit"
              disabled={props.isSubmitting}
            >
              Guardar
            </Button>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  );
};

export default ModalFormNewClient;
